export const subscriptionPlans = [
  {
    name: '"The Ground Floor"',
    id: "free",
    href: "#",
    priceMonthly: "FREE",
    description:
      "Experience our complete feature set at no cost on our Free tier. The modest limits are perfect for casual and light users.",
    mostPopular: false,
  },
  {
    name: '"The Associate"',
    id: "basic",
    href: "#",
    priceMonthly: "$39",
    description:
      "Eliminate hours of mundane work by taking advantage of higher usage limits. Perfect for part-time professionals.",
    mostPopular: false,
  },
  {
    name: '"The Axe"',
    id: "pro",
    href: "#",
    priceMonthly: "$129",
    description: `Lock in your competitive edge with "The Axe" subscription. Catered towards full time professionals, with generous access on conference calls, summaries, and interactions.`,
    mostPopular: true,
  },
  // {
  //   name: "Enterprise",
  //   id: "enterprise",
  //   href: "#",
  //   priceMonthly: "$$$",
  //   description:
  //     "Contact us for higher limits or multiple seats for your organization.",
  //   mostPopular: false,
  // },
];
