import { LoginButton } from "~/auth/routes/login.route";
import { useCurrentUser } from "~/auth/useCurrentUser";
import { cn } from "~/toolkit/components/utils";
import { subscriptionPlans } from "../subscriptionPlans";
import { Link } from "@remix-run/react";

export const PricingCTAButton = ({ planId }: { planId: string }) => {
  let currentUser = useCurrentUser();

  if (!currentUser) {
    return (
      <LoginButton
        returnToPath="/sessions/new"
        intent="signup"
        className={cn(
          planId === "free" ? "btn-secondary" : "btn-primary",
          "btn normal-case btn-block"
        )}
      >
        {planId === "free" ? "Create a free account!" : "Get started free"}
      </LoginButton>
    );
  }
  const currentUserPlanIndex = subscriptionPlans.findIndex(
    (plan) => plan.id === currentUser?.plan
  );
  const planIndex = subscriptionPlans.findIndex((plan) => plan.id === planId);

  const currentUserIsFree = currentUser.plan === "free";

  // if the user is already on this plan, show the manage subscription button
  if (currentUser.plan === planId && planId !== "free") {
    return (
      <Link
        to="/api/stripe-billing"
        className="normal-case btn btn-secondary btn-block"
      >
        Manage your Subscription
      </Link>
    );
  }

  // if the user is on a lower plan, show the upgrade button
  if (currentUserPlanIndex < planIndex) {
    return (
      <Link
        to={
          currentUserIsFree
            ? `/api/stripe-checkout/${planId}`
            : "/api/stripe-billing"
        }
        className="normal-case btn btn-primary btn-block"
      >
        Upgrade Now!
      </Link>
    );
  }

  // if the user is on a higher plan, show the switch plans button
  if (currentUserPlanIndex > planIndex) {
    return (
      <Link
        to="/api/stripe-billing"
        className="normal-case btn btn-primary btn-block"
      >
        Switch Plans
      </Link>
    );
  }

  // User is on a free plan, and this is the free plan
  return <div className="h-12"></div>;
};
